import React from 'react';
import classnames from 'classnames';


export default ({className, src, text, ...attribs}) => (
    <a className={classnames(className, 'icon-link')} {...attribs}>
        <img className="icon-link-img" src={src} alt="" />
        <div className="icon-link-legend">{text}</div>
    </a>
);
