import React from 'react'
import { graphql, Link } from 'gatsby';

import IconLink from './IconLink';

import githubLogo from '../images/github.svg';
import linkedinLogo from '../images/linkedin.svg';
import stackoverflowLogo from '../images/stackoverflow.svg';

export const logos = {
    github: githubLogo,
    linkedin: linkedinLogo,
    stackoverflow: stackoverflowLogo
};



export default class Bio extends React.PureComponent {
  render() {
      const person = this.props.person;
      const profilePic = person.profilePicNode.original.src;
    return (
      <section className="bio">
        <img className="profile-picture" src={profilePic} alt={person.fullName} />
        <h1 className="name">{person.fullName}</h1>
        <p dangerouslySetInnerHTML={{ __html: person.profileHtml }} />
        <ul className="i-links">
            <li><Link className="link" to="/about">▸ more about me</Link></li>
        </ul>
        <ul className="s-links">{person.links.map(({name, url, title}, index) => (
            <li key={index}><IconLink href={url} text={title} src={logos[name]} /></li>
        ))}</ul>
      </section>
    )
  }
}

export const query = graphql`
fragment BioPerson on PersonYaml {
    fullName
    profileHtml
    links { url name title }
    profilePicNode { original { src } }
}
`;
